/** @format */

import React, { Fragment } from 'react';
import $ from 'jquery';
import base from '../base';
import AddSnippetForm from './AddSnippetForm';
import Snippets from './Snippets';
import SavedSnippets from './SavedSnippets';

class App extends React.Component {
	state = {
		snippets: {},
		savedSnippets: {},
		tagsArray: {},
	};

	clearSnippets = () => {
		this.setState({
			snippets: {},
		});
	};

	addSnippet = (snip) => {
		const snippets = { ...this.state.snippets };
		snippets[`snippet-${Date.now()}`] = snip;
		this.setState({
			snippets: snippets,
		});
	};

	saveSnippet = (snip) => {
		//console.log('saving snippet!');
		base.push(`snippets`, {
			data: { snip },
			then(err) {
				if (!err) {
				} else {
					console.log(err);
				}
			},
		});
		setTimeout(() => {
			this.getSavedSnippets();
		}, 500);
	};

	getSavedSnippets = () => {
		base.fetch(`snippets`, {
			context: this,
			asArray: true,
			then(data) {
				this.setState({
					savedSnippets: data,
				});
				this.createTagCloud();
			},
		});
	};

	createTagCloud = () => {
		const cloudArray = [];

		this.state.savedSnippets.map((i) => {
			if (i.snip.tags !== undefined) {
				i.snip.tags.map((j) => {
					cloudArray.push(j);
				});
			}
		});
		const uniqueTags = new Set(cloudArray);
		const uniqueTagsArray = [...uniqueTags];

		this.setState({
			tagsArray: uniqueTagsArray,
		});
		//console.log(uniqueTagsArray);
	};

	// make an array of all currently selected tags.
	selectedTags = [];

	handleClick(e, key) {
		e.preventDefault();

		if (e.target.classList.contains('on')) {
			e.target.classList.remove('on');

			var index = this.selectedTags.indexOf(key);
			this.selectedTags.splice(index, 1);
			//console.log('tags: ' + this.selectedTags);
		} else {
			e.target.classList.add('on');
			this.selectedTags.push(key);
			//console.log('tags: ' + this.selectedTags);
		}

		let buttonCount = document.querySelectorAll('.tag.label.savedlabel-info.on');

		let savedSnips = document.querySelectorAll('.saved-snippet');
		for (var index = 0; index < savedSnips.length; index++) {
			savedSnips[index].classList.add('hide');
			savedSnips[index].classList.remove('show');
		}

		for (var index = 0; index < savedSnips.length; index++) {
			let tags = savedSnips[index].querySelectorAll('.savedlabel-info');

			for (var j = 0; j < tags.length; j++) {
				this.selectedTags.map((i) => {
					if (i === tags[j].innerHTML) {
						tags[j].parentElement.parentElement.classList.remove('hide');
						tags[j].parentElement.parentElement.classList.add('show');
					}
				});
			}
		}
		if (buttonCount.length === 0) {
			for (var index = 0; index < savedSnips.length; index++) {
				savedSnips[index].classList.remove('hide');
				savedSnips[index].classList.add('show');
			}
			return;
		}
	}

	componentDidMount() {
		this.getSavedSnippets();
	}

	render() {
		return (
			<React.Fragment>
				<AddSnippetForm addSnippet={this.addSnippet} />
				<div className='col-lg-6 col-md-12 mb-4 pt-5' style={{ margin: '0 auto', textAlign: 'center' }}>
					<button
						type='button'
						className='btn btn-primary'
						data-toggle='modal'
						data-target='#exampleModal'
						style={{ backgroundColor: '#5bc0de', border: '2px solid #ccc' }}>
						+ Create a New Snippet
					</button>
				</div>
				<div className='col-lg-6 col-md-12 mb-2' style={{ margin: '0 auto', paddingTop: '20px' }}>
					<div
						className='bootstrap-tagsinput saved'
						style={{ borderBottom: 'none', margin: '0 auto', textAlign: 'center', width: '100%' }}>
						{this.state.tagsArray.length > 0 && (
							<h2 className='mb-3' style={{ color: '#000' }}>
								Filter by tag
							</h2>
						)}
						{Object.keys(this.state.tagsArray).map((key) => (
							<a
								key={key}
								href='#'
								style={{ textDecoration: 'none' }}
								onClick={(e) => this.handleClick(e, this.state.tagsArray[key])}>
								<span className='tag label savedlabel-info' style={{ margin: '7px' }}>
									{this.state.tagsArray[key]}
								</span>
							</a>
						))}
					</div>
				</div>
				<Snippets snippets={this.state.snippets} saveSnippet={this.saveSnippet} clearSnippets={this.clearSnippets} />
				<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
					<h2 style={{ margin: '0 auto', fontSize: '2em' }}>My Saved Snippets</h2>
				</div>
				<SavedSnippets snippets={this.state.savedSnippets} />
			</React.Fragment>
		);
	}
}

export default App;
