/** @format */

import React from 'react';
import $ from 'jquery';

class AddSnippetForm extends React.Component {
	snippetName = React.createRef();
	snippetDescription = React.createRef();
	snippetTheme = React.createRef();
	snippetTech = React.createRef();
	snippetInitVal = React.createRef();
	snippetTags = React.createRef();

	clearTags = () => {
		let snippetTags = document.querySelectorAll('.tag.label.label-info');
		for (var index = 0; index < snippetTags.length; index++) {
			var element = snippetTags[index];
			element.parentNode.removeChild(element);
		}
	};

	createSnippet = (event) => {
		event.preventDefault();

		let snippetTags = document.querySelectorAll('.tag.label.label-info');
		const tagsArray = [];
		for (var index = 0; index < snippetTags.length; index++) {
			tagsArray.push(snippetTags[index].textContent);
		}

		const frags = this.snippetTech.current.value.split('|');

		const snippet = {
			name: this.snippetName.current.value,
			description: this.snippetDescription.current.value,
			initialval: this.snippetInitVal.current.value,
			theme: this.snippetTheme.current.value,
			tech: frags[0],
			mode: frags[1],
			modebool: frags[2],
			tags: tagsArray,
		};

		//$('[data-dismiss=modal]').trigger({ type: 'click' });
		console.log('name: ' + this.snippetName.current.value);
		console.log('theme: ' + this.snippetTheme.current.value);
		console.log('tech: ' + this.snippetTech.current.value);

		if (
			this.snippetName.current.value !== '' &&
			this.snippetTheme.current.value !== '' &&
			this.snippetTheme.current.value !== ''
		) {
			this.props.addSnippet(snippet);

			$('[data-dismiss=modal]').trigger({ type: 'click' });
			// this clears the form onSubmit.
			event.currentTarget.reset();
			this.clearTags();
		}
	};

	render() {
		window.addEventListener(
			'keydown',
			function (e) {
				if (e.keyIdentifier == 'Tab' || e.keyCode == 9) {
					if (e.target.nodeName == 'INPUT' && e.target.type == 'text') {
						//alert(true);
						//e.preventDefault();
						//return false;
					}
				}
			},
			true
		);

		return (
			<React.Fragment>
				{/* <div className='col-lg-6 col-md-12 mb-4 pt-5' style={{ margin: '0 auto', textAlign: 'center' }}>
					<button type='button' className='btn btn-primary' data-toggle='modal' data-target='#exampleModal'>
						+ Create a New Snippet
					</button>
				</div> */}
				<div
					className='modal fade'
					id='exampleModal'
					tabIndex='-1'
					role='dialog'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'>
					<div className='modal-dialog'>
						<div className='modal-content'>
							{/* <div className='modal-header'>
								<h5 className='modal-title' id='exampleModalLabel'>
									Modal title
								</h5>
								<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div> */}
							<div className='modal-body'>
								{/* modal content */}

								<div className='card' style={{ margin: '0 auto' }}>
									<h5 className='card-header info-color white-text text-center'>
										<strong>+ Create New Snippet</strong>
									</h5>
									{/* Card content */}
									<div className='card-body px-lg-5 pt-5'>
										{/* Form */}
										<form
											className='text-center needs-validation tooltip-label-top'
											style={{ color: '#757575' }}
											onSubmit={this.createSnippet}
											id='snippetForm'>
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<input
															type='text'
															id='materialRegisterFormSnippetName'
															className='form-control'
															ref={this.snippetName}
															/* style={{ paddingLeft: '50px' }} */
															autoComplete='off'
															required
															tabIndex='-1'
														/>
														<label htmlFor='materialRegisterFormSnippetName'>Name:</label>
														<div className='invalid-tooltip'>A title is required!</div>
													</div>
												</div>
											</div>
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<input
															type='text'
															id='materialRegisterFormTags'
															defaultValue=''
															className='form-control'
															data-role='tagsinput'
															ref={this.snippetTags}
															style={{ width: '100%' }}
															multiple
														/>
														<label htmlFor='materialRegisterFormTags'>Tags:</label>
													</div>
												</div>
											</div>
											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<div className='form-group'>
															<label htmlFor='exampleFormControlTextarea2'>Description:</label>
															<textarea
																ref={this.snippetDescription}
																className='form-control rounded-0'
																id='exampleFormControlTextarea2'
																rows='3'
																style={{ padding: '10px' }}></textarea>
														</div>
													</div>
												</div>
											</div>

											<div className='form-row'>
												<div className='col'>
													<div className='md-form'>
														<div className='form-group'>
															<label htmlFor='exampleFormControlTextarea2'>Initial Value:</label>
															<textarea
																ref={this.snippetInitVal}
																className='form-control rounded-0'
																id='exampleFormControlTextarea2'
																rows='3'
																style={{ padding: '10px' }}></textarea>
														</div>
													</div>
												</div>
											</div>
											<div className='form-row'>
												<div className='col'>
													{/* theme */}
													<div className='md-form'>
														<select className='browser-default custom-select' required ref={this.snippetTheme}>
															<option value=''>Choose a Theme</option>
															<option value='3024-day'>3024-day</option>
															<option value='3024-night'>3024-night</option>
															<option value='abcdef'>abcdef</option>
															<option value='ambiance'>ambiance</option>
															<option value='ayu-dark'>ayu-dark</option>
															<option value='ayu-mirage'>ayu-mirage</option>
															<option value='base16-dark'>base16-dark</option>
															<option value='base16-light'>base16-light</option>
															<option value='bespin'>bespin</option>
															<option value='blackboard'>blackboard</option>
															<option value='cobalt'>cobalt</option>
															<option value='colorforth'>colorforth</option>
															<option value='darcula'>darcula</option>
															<option value='dracula'>dracula</option>
															<option value='duotone-dark'>duotone-dark</option>
															<option value='duotone-light'>duotone-light</option>
															<option value='eclipse'>eclipse</option>
															<option value='elegant'>elegant</option>
															<option value='erlang-dark'>erlang-dark</option>
															<option value='gruvbox-dark'>gruvbox-dark</option>
															<option value='hopscotch'>hopscotch</option>
															<option value='icecoder'>icecoder</option>
															<option value='idea'>idea</option>
															<option value='isotope'>isotope</option>
															<option value='lesser'>lesser-dark</option>
															<option value='liquibyte'>liquibyte</option>
															<option value='lucario'>lucario</option>
															<option value='material</'>material</option>
															<option value='material-darker'>material-darker</option>
															<option value='material-palenight'>material-palenight</option>
															<option value='material-ocean'>material-ocean</option>
															<option value='mbo'>mbo</option>
															<option value='mdn-'>mdn-like</option>
															<option value='midnight'>midnight</option>
															<option value='monokai'>monokai</option>
															<option value='moxer'>moxer</option>
															<option value='neat'>neat</option>
															<option value='neo'>neo</option>
															<option value='night'>night</option>
															<option value='nord'>nord</option>
															<option value='oceanic-next'>oceanic-next</option>
															<option value='panda-syntax'>panda-syntax</option>
															<option value='paraiso-dark'>paraiso-dark</option>
															<option value='paraiso-light'>paraiso-light</option>
															<option value='pastel-on-dark'>pastel-on-dark</option>
															<option value='railscasts'>railscasts</option>
															<option value='rubyblue'>rubyblue</option>
															<option value='seti'>seti</option>
															<option value='shadowfox'>shadowfox</option>
															<option value='solarized dark'>solarized dark</option>
															<option value='solarized light'>solarized light</option>
															<option value='the-matrix'>the-matrix</option>
															<option value='tomorrow-night-bright'>tomorrow-night-bright</option>
															<option value='tomorrow-night-eighties'>tomorrow-night-eighties</option>
															<option value='ttcn'>ttcn</option>
															<option value='twilight'>twilight</option>
															<option value='vibrant-ink'>vibrant-ink</option>
															<option value='xq-dark'>xq-dark</option>
															<option value='xq-light'>xq-light</option>
															<option value='yeti'>yeti</option>
															<option value='yonce'>yonce</option>
															<option value='zenburn'>zenburn</option>
														</select>
														<div className='invalid-tooltip'>A theme is required!</div>
													</div>
												</div>
											</div>

											<div className='form-row'>
												<div className='col'>
													{/* tech */}
													<div className='md-form'>
														<select className='browser-default custom-select' required ref={this.snippetTech}>
															<option value=''>Choose a Technology</option>
															<option value='css|0|0'>CSS</option>
															<option value='clike|0|0'>C-LIKE (JAVA/C++/C#/OBJECTIVE-C)</option>
															<option value='xml|htmlMode|true' rel='true'>
																HTML
															</option>

															<option value='javascript|0|0'>JavaScript</option>
															<option value='javascript|json|true'>JSON</option>
															<option value='null|0|0'>Plaintext</option>
															<option value='xml|0|0' rel='false'>
																XML
															</option>
														</select>
														<div className='invalid-tooltip'>A technology is required!</div>
													</div>
												</div>
											</div>
											{/* Submit */}
											<button type='button' className='btn btn-info btn-block' data-dismiss='modal'>
												Cancel
											</button>

											<button
												id='snipSubmit'
												className='btn btn-info btn-block'
												type='submit'
												style={{ fontSize: '1rem', padding: '4px' }}
												data-target='#exampleModal'>
												Make Snippet +
											</button>
										</form>

										{/* Form */}
									</div>
								</div>

								{/* modal content */}
							</div>
							{/* <div className='modal-footer'>
								<button type='button' className='btn btn-secondary' data-dismiss='modal'>
									Close
								</button>
								<button type='button' className='btn btn-primary'>
									Save changes
								</button>
							</div> */}
						</div>
					</div>
				</div>
				{/* Material form register */}
				{/* <div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
					<form>
						<div className='card' style={{ margin: '0 auto', marginTop: '30px' }}>
							<div className='card-body px-lg-5 pt-0'>
								<div className='form-group row'>
									<label for='inputName' className='col-sm-2 col-form-label'>
										Name
									</label>
									<div className='col-sm-10'>
										<input type='text' autoComplete='off' className='form-control' id='inputName' placeholder='Name' />
									</div>
								</div>
								<div className='form-group row'>
									<label for='tagsInput' className='col-sm-2 col-form-label'>
										Tags
									</label>
									<div className='col-sm-10'>
										<input type='text' data-role='tagsinput' className='form-control' id='tagsInput' placeholder='Tag' />
									</div>
								</div>
							</div>{' '}
						</div>
					</form>
				</div> */}

				{/* <div className='col-lg-6 col-md-12 mb-4 pt-5' style={{ margin: '0 auto' }}></div> */}
			</React.Fragment>
		);
	}
}

export default AddSnippetForm;
