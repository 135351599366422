/** @format */

import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyAGcgsH3v8cmqCfsynQAvd6Lqc0sMs_PIk',
	authDomain: 'snippets-app-8097e.firebaseapp.com',
	databaseURL: 'https://snippets-app-8097e.firebaseio.com',
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };
export default base;
