/** @format */

import React from 'react';
import $, { timers } from 'jquery';
import { UnControlled as CodeMirror } from 'react-codemirror2';

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/xml/xml');
require('codemirror/mode/css/css');
require('codemirror/mode/clike/clike');
require('codemirror/addon/selection/active-line');
require('codemirror/addon/scroll/simplescrollbars');

class Snippet extends React.Component {
	state = {
		thisSnippet: '',
	};

	alertMe = (name) => {
		alert(name);
	};
	returnSnipValue = (val) => {
		this.setState({
			thisSnippet: val,
		});
	};

	saveSnippet = (name, description, theme, tech, mode, modebool, tags, initialval) => {
		const savedSnippet = {
			name: name,
			description: description,
			theme: theme,
			tech: tech,
			mode: mode,
			modebool: modebool,
			tags: tags,
			initialval: initialval,
		};

		this.props.saveSnippet(savedSnippet);
		this.deleteSnippet(this.props.children);
	};

	deleteSnippet = (id) => {
		let thisSnippet = document.querySelector(`#${id}`);
		var element = thisSnippet;
		element.parentNode.removeChild(element);
	};

	componentDidMount() {}

	render() {
		const { name, description, initialval, theme, tech, mode, modebool } = this.props.details;
		return (
			<React.Fragment>
				<div
					id={this.props.children}
					style={{ backgroundColor: '#666', padding: '20px', border: '1px solid #999', marginBottom: '30px' }}>
					<h2>{name}</h2>
					<p>{description}</p>
					{tech === 'clike' && mode === '0' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'clike' },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
							}}
						/>
					)}
					{tech === 'css' && mode === '0' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'css' },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
							}}
						/>
					)}
					{tech === 'xml' && mode === 'htmlMode' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'xml', htmlMode: true },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
							}}
						/>
					)}
					{tech === 'xml' && mode === '0' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'xml' },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
							}}
						/>
					)}
					{tech === 'javascript' && mode === '0' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'javascript' },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
							}}
						/>
					)}
					{tech === 'javascript' && mode === 'json' && (
						<>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'javascript', json: true },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'null' && mode === '0' && (
						<>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'null' },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}

					<br />
					<div className='bootstrap-tagsinput' style={{ borderBottom: 'none', paddingLeft: '0px' }}>
						{this.props.details.tags.map((i, index) => {
							return (
								<span key={i} className='tag label savedlabel-info' style={{ marginRight: '10px' }}>
									{i}
								</span>
							);
						})}
					</div>
					<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
						<button
							className='btn btn-info my-4 btn-block'
							/* style={{ fontSize: '1rem', float: 'left', width: '32%', marginRight: '11px' }} */
							onClick={(e) => this.deleteSnippet(this.props.children)}
							rel={this.props.details.name}>
							Cancel Snippet
						</button>

						{/* <div style={{ clear: 'both' }}></div> */}
					</div>
					<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
						<button
							className='btn btn-info my-4 btn-block'
							/* style={{ fontSize: '1rem', float: 'left', width: '32%', marginRight: '11px' }} */
							onClick={(e) => this.instance.setValue('')}
							rel={this.props.details.name}>
							Clear Snippet
						</button>
					</div>
					<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
						<button
							className='btn btn-info my-4 btn-block'
							/* style={{ fontSize: '1rem', float: 'left', width: '32%', marginRight: '11px' }} */
							onClick={(e) =>
								this.saveSnippet(
									this.props.details.name,
									this.props.details.description,
									this.props.details.theme,
									this.props.details.tech,
									this.props.details.mode,
									this.props.details.modebool,
									this.props.details.tags,
									this.instance.getValue()
								)
							}>
							Save Snippet
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Snippet;
