/** @format */

import React from 'react';
import $ from 'jquery';
import Snippet from './Snippet';

class Snippets extends React.Component {
	render() {
		const size = Object.keys(this.props.snippets).length;
		return (
			<React.Fragment>
				<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
					<div className='list disable-text-selection' data-check-all='checkAll'>
						{Object.keys(this.props.snippets).map((key) => (
							<Snippet
								clearSnippets={this.props.clearSnippets}
								saveSnippet={this.props.saveSnippet}
								key={key}
								details={this.props.snippets[key]}>
								{key}
							</Snippet>
						))}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Snippets;
