/** @format */

import React from 'react';
import $, { timers } from 'jquery';
import { UnControlled as CodeMirror } from 'react-codemirror2';

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/xml/xml');
require('codemirror/mode/css/css');
require('codemirror/mode/clike/clike');
require('codemirror/addon/selection/active-line');

class SavedSnippet extends React.Component {
	state = {
		thisSnippet: '',
	};

	alertMe = (name) => {
		alert(name);
	};
	returnSnipValue = (val) => {
		this.setState({
			thisSnippet: val,
		});
	};

	componentDidMount() {}

	render() {
		const { name, description, initialval, theme, tech, mode, modebool } = this.props.details;
		return (
			<React.Fragment>
				<div
					className='saved-snippet'
					style={{ backgroundColor: '#666', padding: '20px', border: '1px solid #999', marginBottom: '30px' }}>
					<h2>{name}</h2>
					<p style={{ fontWeight: '500' }}>{description}</p>

					{tech === 'clike' && mode === '0' && (
						<>
							<p>
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-csharp-plain-wordmark'></i>
								</a>
								&#160;&#160;
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-cplusplus-plain-wordmark'></i>
								</a>
								&#160;&#160;
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-c-plain-wordmark'></i>
								</a>
								&#160;&#160;
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-java-plain-wordmark'></i>
								</a>
								&#160;&#160;
							</p>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								/* value={`\n\n\n`} */
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'clike' },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'css' && mode === '0' && (
						<>
							<p>
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-css3-plain-wordmark'></i>
								</a>
								&#160;&#160;
							</p>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'css' },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'xml' && mode === 'htmlMode' && (
						<>
							<p>
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-html5-plain-wordmark'></i>
								</a>
								&#160;&#160;
							</p>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'xml', htmlMode: true },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'xml' && mode === '0' && (
						<CodeMirror
							editorDidMount={(editor) => {
								this.instance = editor;
							}}
							returnSnipValue={this.returnSnipValue}
							details={this.props.details}
							theme={this.props.details.theme}
							tech={this.props.details.tech}
							value={`${this.props.details.initialval}`}
							options={{
								mode: { name: 'xml' },
								theme: `${theme}`,
								lineNumbers: true,
								styleActiveLine: true,
								lineWrapping: true,
								readOnly: true,
							}}
							onChange={(editor, data, value) => {
								this.returnSnipValue(value);
								//console.log('XXX:' + editor.options.mode.name);
							}}
						/>
					)}
					{tech === 'javascript' && mode === '0' && (
						<>
							<p>
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-javascript-plain'></i>
								</a>
								&#160;&#160;
							</p>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'javascript' },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'javascript' && mode === 'json' && (
						<>
							<p>
								<a
									style={{
										fontSize: '40px',
										color: 'rgb(255, 115, 0)',
									}}>
									<i className='devicon-javascript-plain'></i>
								</a>
								&#160;&#160;
							</p>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'javascript', json: true },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{tech === 'null' && mode === '0' && (
						<>
							<CodeMirror
								editorDidMount={(editor) => {
									this.instance = editor;
								}}
								returnSnipValue={this.returnSnipValue}
								details={this.props.details}
								theme={this.props.details.theme}
								tech={this.props.details.tech}
								value={`${this.props.details.initialval}`}
								options={{
									mode: { name: 'null' },
									theme: `${theme}`,
									lineNumbers: true,
									styleActiveLine: true,
									lineWrapping: true,
									scrollbarStyle: 'simple',
									readOnly: true,
								}}
								onChange={(editor, data, value) => {
									this.returnSnipValue(value);
								}}
							/>
						</>
					)}
					{this.props.details.tags !== undefined && (
						<>
							<br />
							<div className='bootstrap-tagsinput saved' style={{ borderBottom: 'none', paddingLeft: '0px' }}>
								{this.props.details.tags.map((i, index) => {
									return (
										<span className='tag label savedlabel-info' style={{ marginRight: '10px' }} key={i}>
											{i}
										</span>
									);
								})}
							</div>
						</>
					)}
					<hr style={{ borderTop: '6px solid #666' }} />
				</div>
			</React.Fragment>
		);
	}
}

export default SavedSnippet;
