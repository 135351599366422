/** @format */

import React from 'react';
import $ from 'jquery';
import SavedSnippet from './SavedSnippet';

class SavedSnippets extends React.Component {
	render() {
		const size = Object.keys(this.props.snippets).length;
		return (
			<React.Fragment>
				<div className='col-lg-6 col-md-12 mb-4' style={{ margin: '0 auto' }}>
					<div className='list disable-text-selection' data-check-all='checkAll'>
						<p style={{ fontWeight: '500' }}>You have {size} saved snippets.</p>
						{Object.keys(this.props.snippets)
							.reverse()
							.map((key) => (
								<SavedSnippet
									saveSnippet={this.props.saveSnippet}
									key={key}
									details={this.props.snippets[key].snip}
									firebasekey={this.props.snippets[key].key}>
									{key}
								</SavedSnippet>
							))}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default SavedSnippets;
